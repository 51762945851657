export const palette = {
  primary: {
    main: '#F6ECA9'
  },
  secondary: {
    main: '#0A192F'
  },
  error: {
    main: '#f44336'
  },
  background: {
    default: '#0A192F',
    secondary: '#112240',
    iconBg: '#F2F2F2',
    hover: '#D9EDF3'
  },
  dividerCl: {
    mainCl: '#E0E6FB',
    secondaryCl: '#8892B0'
  },
  action: {
    selected: '#253351',
    hover: '#D9EDF3'
  },
  button: {
    dark: '#176B87',
    primaryLight: '#E75D74'
  },
  text: {
    primary: '#E0E6FB',
    secondary: '#8892B0'
  },
  status: {
    completed: '#33A066',
    inProgress: '#D9EDF3',
    pending: '#E6CE79',
    failed: '#E75D74'
  }
}
