import { To } from 'react-router-dom'

// material-ui
import { ButtonBase, Stack, Typography } from '@mui/material'
import { SxProps } from '@mui/system'

// project import
import LogoIcon from './LogoIcon'
import Logo from './LogoMain'

// ==============================|| MAIN LOGO ||============================== //

interface Props {
  reverse?: boolean
  isIcon?: boolean
  text?: string
  sx?: SxProps
  to?: To
  href?: string
  color?: string
}

const LogoSection = ({
  reverse,
  isIcon,
  text,
  sx,
  to,
  href,
  color = 'inherit'
}: Props) => (
  <ButtonBase disableRipple sx={{ mt: 2, mb: 3, ...sx }}>
    <a href={href} style={{ textDecoration: 'none', color }}>
      <Stack direction="row" alignItems="center" spacing={2}>
        {isIcon ? (
          <LogoIcon />
        ) : (
          <>
            <Logo reverse={reverse} />
            {text && (
              <Typography
                variant="h6"
                sx={{ marginLeft: 2, fontSize: '1.25rem', color: color }}>
                {text}
              </Typography>
            )}
          </>
        )}
      </Stack>
    </a>
  </ButtonBase>
)

export default LogoSection
