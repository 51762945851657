// material-ui
import { Box, Button, Container, Grid, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

// third party
import { motion } from 'framer-motion'

// assets
import AnimateButton from '../../common/extendedbuttons/AnimateButton'
import { palette } from '../../theme/palette'
import { Helmet } from 'react-helmet'

// ==============================|| LANDING - HEADER PAGE ||============================== //

const HeroPage = () => {
  const theme = useTheme()

  const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId)
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  return (
    <Container
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        px: { xs: 1.5, md: 0 }
      }}>
      <Helmet>
        <title>Faisal Karim - Frontend Developer</title>
        <meta
          name="description"
          content="Faisal Karim's personal portfolio showcasing frontend development skills and projects."
        />
      </Helmet>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
        sx={{
          pt: { md: 2, xs: 8 },
          pb: { md: 0, xs: 5 },
          mx: { md: 15, sm: 4, xs: 1 }
        }}>
        <Grid item xs={11} md={12}>
          <Grid
            container
            spacing={2}
            sx={{
              pr: 10,
              [theme.breakpoints.down('md')]: { pr: 0, textAlign: 'left' }
            }}>
            <Grid item xs={12}>
              <motion.div
                initial={{ opacity: 0, translateY: 550 }}
                animate={{ opacity: 1, translateY: 0 }}
                transition={{
                  type: 'spring',
                  stiffness: 150,
                  damping: 30
                }}>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    color: theme.palette.primary.main,
                    fontSize: { xs: '1.0rem', sm: '1.3rem', md: '1.2rem' },
                    fontWeight: 400,
                    lineHeight: { xs: 1.5, md: 1.4 }
                  }}>
                  Hi, my name is
                </Typography>
              </motion.div>
            </Grid>
            <Grid item xs={12}>
              <motion.div
                initial={{ opacity: 0, translateY: 550 }}
                animate={{ opacity: 1, translateY: 0 }}
                transition={{
                  type: 'spring',
                  stiffness: 150,
                  damping: 30,
                  delay: 0.2
                }}>
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: { xs: '2.2rem', sm: '3.4rem', md: '4.0rem' },
                    fontWeight: 700,
                    lineHeight: { xs: 1.1, sm: 1.3, md: 0.9 },
                    color: theme.palette.text.primary
                  }}>
                  Faisal Karim.
                </Typography>
              </motion.div>
            </Grid>
            <Grid item xs={12}>
              <motion.div
                initial={{ opacity: 0, translateY: 550 }}
                animate={{ opacity: 1, translateY: 0 }}
                transition={{
                  type: 'spring',
                  stiffness: 150,
                  damping: 30,
                  delay: 0.2
                }}>
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: { xs: '1.9rem', sm: '3.2rem', md: '4.0rem' },
                    fontWeight: 700,
                    lineHeight: { xs: 1.1, sm: 1.1, md: 0.9 },
                    color: theme.palette.text.secondary
                  }}>
                  I build things for the web.
                </Typography>
              </motion.div>
            </Grid>
            <Grid item xs={12}>
              <motion.div
                initial={{ opacity: 0, translateY: 550 }}
                animate={{ opacity: 1, translateY: 0 }}
                transition={{
                  type: 'spring',
                  stiffness: 150,
                  damping: 30,
                  delay: 0.2
                }}>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    fontSize: { xs: '1.21em', md: '1rem' },
                    fontWeight: 400,
                    lineHeight: { xs: 1.5, md: 1.4 },
                    color: theme.palette.text.secondary,
                    maxWidth: { sm: '87%', md: '70%' }
                  }}>
                  I am a software developer dedicated to creating (and sometimes
                  designing) outstanding digital experiences. At present, my
                  focus is on developing accessible, user-centric products at
                  <Box
                    component="span"
                    sx={{ color: theme.palette.primary.main }}>
                    <span> Bosch</span>
                  </Box>
                </Typography>
              </motion.div>
            </Grid>
            <Grid item xs={12} sx={{ my: 3.25 }}>
              <motion.div
                initial={{ opacity: 0, translateY: 550 }}
                animate={{ opacity: 1, translateY: 0 }}
                transition={{
                  type: 'spring',
                  stiffness: 150,
                  damping: 30,
                  delay: 0.4
                }}>
                <Grid container spacing={2} justifyContent="left">
                  <Grid item xs={6} md={6} lg={6}>
                    <AnimateButton>
                      <Button
                        onClick={() => scrollToSection('about')}
                        sx={{
                          borderRadius: 2,
                          textTransform: 'none',
                          px: { xs: '20px', md: '25px', lg: '30px' },
                          color: 'palette.primary.main',
                          border: `1px solid ${palette.primary.main}`,
                          fontSize: { xs: '1.0rem', sm: '1.0rem', md: '1.2rem' }
                        }}>
                        Learn More
                      </Button>
                    </AnimateButton>
                  </Grid>
                </Grid>
              </motion.div>
            </Grid>
            <Grid item xs={12}>
              <motion.div
                initial={{ opacity: 0, translateY: 550 }}
                animate={{ opacity: 1, translateY: 0 }}
                transition={{
                  type: 'spring',
                  stiffness: 150,
                  damping: 30,
                  delay: 0.6
                }}></motion.div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default HeroPage
