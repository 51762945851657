import { Grid } from '@mui/material'
import { motion } from 'framer-motion'
import profileImg from '../../../assets/images/profileImg.png'
import ProfileImage from './ProfileImage'

const ProfileSection: React.FC = () => (
  <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
    <motion.div
      initial={{ opacity: 0, translateY: 550 }}
      animate={{ opacity: 1, translateY: 0 }}
      transition={{
        type: 'spring',
        stiffness: 150,
        damping: 30,
        delay: 0.2
      }}>
      <ProfileImage src={profileImg} alt="profilePicture" />
    </motion.div>
  </Grid>
)

export default ProfileSection
