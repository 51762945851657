import { Box, Divider } from '@mui/material'
import { motion } from 'framer-motion'
import React from 'react'
import { theme } from '../../theme'
import EmailLink from './EmailLink'
import SocialMediaLink from './socialMediaLinks'

interface SocialMediaIconsProps {
  flexDirection: 'row' | 'column'
  includeEmailLink?: boolean
}

const SocialMediaIcons: React.FC<SocialMediaIconsProps> = ({
  flexDirection,
  includeEmailLink = true
}) => {
  const email = 'faisalkarim@dr.com'

  return (
    <motion.div
      initial={{ opacity: 0, translateY: 550 }}
      animate={{ opacity: 1, translateY: 0 }}
      transition={{
        type: 'spring',
        stiffness: 150,
        damping: 30,
        delay: 0.2
      }}>
      <Box
        sx={{
          px: { md: 6, lg: 6 },
          position: 'fixed',
          left: 0,
          right: 0,
          bottom: 0,
          display: { xs: 'none', md: 'flex' },
          alignItems: 'flex-end',
          justifyContent: 'space-between'
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
          }}>
          <SocialMediaLink platform="github" username="karimfaisall" />
          <SocialMediaLink platform="linkedin" username="karimfaisal-dev" />
          <SocialMediaLink platform="instagram" username="karimfaisall" />
          <Divider
            orientation="vertical"
            sx={{
              height: '90px',
              ml: 1.2,
              borderColor: theme.palette.text.secondary
            }}
          />
        </Box>

        {includeEmailLink && (
          <EmailLink
            email={email}
            orientation={flexDirection === 'column' ? 'vertical' : 'horizontal'}
          />
        )}
      </Box>
    </motion.div>
  )
}

export default SocialMediaIcons
