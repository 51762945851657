import { Grid } from '@mui/material'
import { Technology } from '../../../types/aboutPage'
import TechnologyList from './technologies'

interface TechnologyDisplayProps {
  firstHalfTechnologies: Technology[]
  secondHalfTechnologies: Technology[]
}

const TechnologyDisplay: React.FC<TechnologyDisplayProps> = ({
  firstHalfTechnologies,
  secondHalfTechnologies
}) => (
  <Grid
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'nowrap',
      ml: -3,
      mb: 2
    }}>
    <Grid container>
      <Grid item xs={6} sm={6}>
        <TechnologyList technologies={firstHalfTechnologies} />
      </Grid>
      <Grid item xs={6} sm={6}>
        <TechnologyList technologies={secondHalfTechnologies} />
      </Grid>
    </Grid>
  </Grid>
)

export default TechnologyDisplay
