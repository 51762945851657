import { Box, useTheme } from '@mui/material'

const ProfileImage: React.FC<{ src: string; alt: string }> = ({ src, alt }) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        mr: { xs: '69px', sm: '69px' },
        position: 'relative',
        display: 'inline-block',
        width: { xs: '200px', sm: '330px', md: '330px', lg: '300px' },
        height: '300',
        '&:hover img': {
          transform: 'translateX(-3px) translateY(-3px)' // Moves the image to the top left corner
        },
        '&:hover .overlay': {
          opacity: 0
        },
        '&:hover .line': {
          transform: 'translateX(3px) translateY(3px)' // Moves the line to the bottom right corner
        }
      }}>
      <Box
        className="line"
        sx={{
          position: 'absolute',
          top: 10,
          left: 10,
          width: 'calc(100% + 3px)',
          height: 'calc(100% + 3px)',
          bgcolor: 'transparent',
          border: `2px solid ${theme.palette.primary.main}`,
          borderRadius: '6px',
          transition: 'transform 0.1s ease-in-out',
          zIndex: 0
        }}
      />
      <Box
        className="overlay"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          borderRadius: '6px',
          width: '100%',
          height: '100%',
          bgcolor: 'rgba(239, 228, 157, 0.22)',
          transition: 'opacity 0.3s ease-in-out',
          zIndex: 3
        }}
      />
      <img
        src={src}
        alt={alt}
        style={{
          display: 'block',
          position: 'relative',
          zIndex: 2,
          width: '100%',
          height: '100%',
          transition: 'transform 0.1s ease-in-out'
        }}
      />
    </Box>
  )
}

export default ProfileImage
