import { Theme } from '@mui/material/styles'

export const textStyle = (
  theme: Theme,
  variant: 'body1' | 'header' = 'body1'
) => {
  return {
    fontSize:
      variant === 'body1'
        ? { xs: '0.9rem', md: '1.0rem' }
        : { xs: '0.9rem', sm: '1.2rem', md: '1.3rem' },
    fontWeight: variant === 'body1' ? 400 : 700,
    color:
      variant === 'body1'
        ? theme.palette.text.secondary
        : theme.palette.primary.main,
    lineHeight: { xs: 1.9, sm: 1.6, md: 1.5 },
    maxWidth: { xs: '90%', sm: '90%', md: '90%', lg: '100%' },
    mb: '25px'
  }
}
