import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined'
import {
  Box,
  Container,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
  styled
} from '@mui/material'
import { motion } from 'framer-motion'
import { useState } from 'react'
import { theme } from '../../theme'
import HeaderSection from '../About/header/HeaderSection'
import { Helmet } from 'react-helmet'

const ColorlibListItem = styled(ListItem)(({ theme, selected }) => ({
  cursor: 'pointer',
  position: 'relative',
  '&.Mui-selected, &.Mui-selected:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.main
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    width: '3px',
    height: '100%',
    backgroundColor: selected
      ? theme.palette.primary.main
      : theme.palette.action.selected,
    transition: 'background-color 0.6s',
    borderRadius: '3px'
  },
  '&:hover:before': {
    backgroundColor: selected
      ? theme.palette.primary.main
      : theme.palette.action.selected,
    transition: 'background-color 0.6s'
  }
}))

const workExperience = [
  {
    company: 'TRILUX DS GmbH',
    role: 'Senior Fullstack Engineer',
    link: 'https://triluxds.com/',
    duration: 'April 2024 – Present',
    description: [
      'Architect and develop end-to-end fullstack solutions using React, Node.js, and TypeScript, ensuring performance, scalability, and maintainability.',
      'Lead the development of microservices-based architectures, integrating with RESTful APIs and GraphQL for optimized data communication.',
      'Utilize cloud platforms like AWS and Docker to deploy and manage applications in scalable and secure environments.',
      'Implement CI/CD pipelines to automate testing and deployment, improving delivery speed and reducing manual processes.',
      'Collaborate with product teams and designers to build user-centric web applications that enhance user experience and business value.'
    ]
  },
  {
    company: 'Bosch',
    role: 'Frontend Web Developer',
    link: 'https://www.bosch.com',
    duration: 'June 2018 – March 2024',
    description: [
      'Architected and implemented a responsive, mobile-first web application, focusing on accessibility and cross-browser compatibility, leveraging modern technologies like React.js, Vuex, and SCSS.',
      'Drove the migration of legacy front-end codebase to a modern JavaScript framework (React), significantly improving maintainability, performance, and scalability of the application.',
      'Played a pivotal role in the integration of RESTful APIs and third-party services, enhancing application functionality and data interaction in a seamless and efficient manner.',
      'Collaborated closely with UX/UI designers to translate intricate designs into functional, dynamic web pages using HTML5, CSS3, and JavaScript ES6+, ensuring pixel-perfect implementation and a seamless user experience.'
    ]
  },
  {
    company: 'Lawpilots',
    role: 'Software Engineer',
    link: 'https://lawpilots.com',
    duration: 'May 2017 – May 2018',
    description: [
      'Designed and implemented a scalable, component-based architecture for the LMS platform using React and Redux, facilitating easy maintenance and feature expansion while handling thousands of concurrent users.',
      'Pioneered the use of React Hooks and Context API in the LMS to manage global state and user sessions, leading to a more intuitive coding structure and enhanced performance.',
      'Collaborated with backend developers to design and consume RESTful APIs for the LMS, ensuring seamless data synchronization and real-time updates using React efficient rendering capabilities.',
      'Implemented a responsive and accessible user interface for the LMS with React, focusing on mobile-first design principles and WCAG accessibility guidelines, thus catering to a diverse user base.'
    ]
  },
  {
    company: 'Canon',
    role: 'Junior Software Engineer',
    link: 'https://www.canon.de',
    duration: 'June 2015 – February 2017',
    description: [
      'Assisted in building responsive web interfaces using HTML5, CSS3, and JavaScript, contributing to user-friendly design and functionality across multiple web applications.',
      'Participated in the development of an e-commerce website, focusing on creating interactive product galleries and shopping carts using React.js and Redux.',
      'Contributed to the maintenance and enhancement of the company’s main website, implementing UI improvements and bug fixes to improve user experience.',
      'Supported cross-browser testing and debugging, ensuring compatibility and consistent performance across various platforms and devices.',
      'Gained experience in version control using Git, participating in regular team commits, pull requests, and code merges.'
    ]
  },
  {
    company: 'Flybits',
    role: 'Junior Software Engineer',
    link: 'https://flybits.com/',
    duration: 'December 2014 – April 2015',
    description: [
      'Assisted in building interactive web components for a company internal dashboard using HTML, CSS, Sass, JavaScript, and jQuery, gaining hands-on experience with modern front-end technologies.',
      'Collaborated in a team environment to develop a responsive client-facing web application, contributing to various features and bug fixes while adhering to coding standards and best practices.',
      'Participated in the development of an e-commerce website using React, focusing on creating user-friendly product listing pages with dynamic filtering and sorting capabilities.',
      'Assisted in integrating RESTful APIs in a React application, gaining experience in handling JSON data and understanding asynchronous programming concepts.',
      'Supported the development of responsive web layouts using CSS and Flexbox, ensuring compatibility across various devices and screen sizes.'
    ]
  }
]

const ExperiencePage: React.FC = () => {
  const [selectedWorkPlace, setSelectedWorkPlace] = useState(0)
  const selectedCompany = workExperience[selectedWorkPlace].company

  return (
    <Container
      sx={{
        minHeight: '60vh',
        display: 'flex',
        alignItems: 'flex-start'
      }}>
      <Helmet>
        <title>Experience - Faisal Karim</title>
        <meta
          name="description"
          content={`Read about Faisal Karim's work experience, including his role at ${selectedCompany}.`}
        />
      </Helmet>

      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
        sx={{
          mt: { md: 9, xs: 8 },
          pb: { md: 0, xs: 5 },
          ml: { md: 17, xs: 1, sm: 3, lg: 32 },
          mr: { md: 20, xs: 3, sm: 21, lg: 1 }
        }}>
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            sx={{
              [theme.breakpoints.down('md')]: { pr: 0, textAlign: 'left' }
            }}>
            <Grid item xs={12}>
              <motion.div
                initial={{ opacity: 0, translateY: 550 }}
                animate={{ opacity: 1, translateY: 0 }}
                transition={{
                  type: 'spring',
                  stiffness: 150,
                  damping: 30,
                  delay: 0.3
                }}>
                <HeaderSection
                  theme={theme}
                  number="02. "
                  title="Where I’ve Worked"
                  dividerWidth={{ xs: '100px', sm: '120px', md: '200px' }}
                />
              </motion.div>
            </Grid>
            <Grid
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'nowrap',
                ml: 1,
                mb: 2
              }}>
              <Grid container>
                <Grid item xs={11} sm={12} md={3}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: {
                        xs: 'row',
                        sm: 'row',
                        md: 'column',
                        lg: 'column'
                      },
                      overflowX: {
                        xs: 'auto',
                        sm: 'auto',
                        md: 'hidden',
                        lg: 'hidden'
                      },
                      '&::-webkit-scrollbar': {
                        width: '0px'
                      }
                    }}>
                    <List
                      component="nav"
                      aria-label="secondary mailbox folders"
                      sx={{
                        display: 'flex',
                        flexDirection: {
                          xs: 'row',
                          sm: 'row',
                          md: 'column',
                          lg: 'column'
                        },
                        padding: 0,
                        width: '100%'
                      }}>
                      {workExperience.map((place, index) => (
                        <ColorlibListItem
                          key={place.company}
                          onClick={() => setSelectedWorkPlace(index)}
                          sx={{
                            bgcolor:
                              selectedWorkPlace === index
                                ? theme.palette.action.selected
                                : 'defaultColor',
                            color:
                              selectedWorkPlace === index
                                ? theme.palette.primary.main
                                : 'inherit',
                            borderLeft:
                              selectedWorkPlace === index
                                ? `2px solid ${theme.palette.primary.main}`
                                : 'defaultColor',
                            borderRadius: '1px',
                            width: {
                              xs: 'auto',
                              sm: 'auto',
                              md: 'auto',
                              lg: 'auto'
                            },
                            mr: { xs: -1, sm: -1, md: 1, lg: 11 }
                          }}>
                          <ListItemText primary={place.company} />
                        </ColorlibListItem>
                      ))}
                    </List>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={8}>
                  <Box
                    sx={{
                      mr: { xs: 7, sm: -1, md: 1, lg: 11 }
                    }}>
                    <Typography variant="h5" gutterBottom sx={{ mt: '19px' }}>
                      {workExperience[selectedWorkPlace].role}{' '}
                      <Link
                        href={workExperience[selectedWorkPlace].link}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                          color: theme.palette.primary.main,
                          textDecoration: 'none'
                        }}>
                        @ {workExperience[selectedWorkPlace].company}
                      </Link>
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      fontFamily="Fira Code, monospace">
                      {workExperience[selectedWorkPlace].duration}
                    </Typography>
                    {workExperience[selectedWorkPlace].description.map(
                      (desc, index) => (
                        <Box
                          key={index}
                          sx={{ display: 'flex', alignItems: 'center' }}>
                          <PlayArrowOutlinedIcon color="primary" />
                          <Typography
                            variant="body1"
                            color="textSecondary"
                            sx={{ mt: '21px', ml: '22px' }}>
                            {desc}
                          </Typography>
                        </Box>
                      )
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ExperiencePage
