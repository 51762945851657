import { RouteObject } from 'react-router-dom'
import MainLayout from '../layout/MainLayout'
import Landing from '../sections'

const MainRoutes: RouteObject[] = [
  {
    path: '/',
    element: (
      <MainLayout>
        <Landing />
      </MainLayout>
    )
  }
]

export default MainRoutes
