import { useEffect, useState } from 'react'

// material-ui
import { Box } from '@mui/material'

// project import
import { Technology } from '../types/aboutPage'
import AboutPage from './About/AboutPage'
import Experience from './Experience/ExperiencePage'
import FooterBlock from './FooterBlock'
import HeroPage from './Hero/HeroPage'

// third-party

// assets
// types

// ==============================|| LANDING PAGE ||============================== //

const Landing = () => {
  const [visible, setVisible] = useState(false)

  const technologies: Technology[] = [
    'JavaScript (ES6+)',
    'React/Angular',
    'PostgreSQL/MongoDB',
    'TypeScript',
    'Node.js/Express',
    'AWS/Azure'
  ]

  useEffect(() => {
    const listenToScroll = () => {
      const heightToHideFrom = 250
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop

      if (winScroll > heightToHideFrom) {
        setVisible(true)
      } else {
        visible && setVisible(false)
      }
    }

    window.addEventListener('scroll', listenToScroll)
    return () => window.removeEventListener('scroll', listenToScroll)
  }, [visible])

  return (
    <div>
      <Box
        sx={{
          background: '#0A192F'
        }}
        id="heroPage">
        <HeroPage />
      </Box>

      <div
        id="about"
        style={{
          background: '#0A192F'
        }}>
        <AboutPage technologies={technologies} />
      </div>

      <div
        id="experience"
        style={{
          background: '#0A192F'
        }}>
        <Experience />
      </div>

      {/* <div
        id="work"
        style={{
          background: '#0A192F'
        }}>
        <WorkPage />
      </div> */}
      <div id="contact">
        <FooterBlock />
      </div>
    </div>
  )
}

export default Landing
