// material-ui
import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material'

// third party

// project import

// assets

// types
import SocialMediaLink from '../common/socialMediaIcons/socialMediaLinks'
import { palette } from '../theme/palette'

// link - custom style

// ==============================|| LANDING - FOOTER PAGE ||============================== //

const FooterBlock = () => {
  const email = 'faisalkarim@dr.com'
  const mailtoLink = `mailto:${email}`

  return (
    <Box>
      <Container
        sx={{
          minHeight: '45vh',
          display: 'flex',
          alignItems: 'center',
          bgcolor: '#0A192F'
        }}>
        <Grid
          container
          spacing={4}
          direction="column"
          alignItems="center"
          justifyContent="center">
          <Grid item xs={12}>
            <Stack spacing={2} justifyContent="center" alignItems="center">
              <Typography align="center" variant="h1">
                Get In Touch
              </Typography>
              <Typography
                color="textSecondary"
                align="center"
                sx={{ width: '65%' }}>
                Looking for an attractive, well-organized website that's easy
                for you to manage and maintain? Feel free to reach out.
              </Typography>
              <Button
                variant="outlined"
                href={mailtoLink}
                sx={{
                  mr: '4px',
                  color: 'palette.primary.main',
                  border: `1px solid ${palette.primary.main}`,
                  borderRadius: '5px',
                  textTransform: 'none',
                  px: '30px',
                  my: '90px',
                  fontSize: { xs: '1.0rem', md: '1.2rem' },
                  fontFamily: 'Fira Code, monospace'
                }}>
                Say Hello
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Container>

      <Container
        sx={{
          py: 1.5,
          display: 'flex',
          alignItems: 'flex-start'
        }}>
        <Grid
          container
          spacing={4}
          direction="column"
          alignItems="center"
          justifyContent="center">
          <Grid item xs={12}>
            <Grid
              item
              xs={12}
              direction="column"
              alignItems="flex-start"
              justifyContent="flex-start"
              sx={{ display: { xs: 'flex', md: 'none' }, ml: '35px' }}>
              <Grid item xs={12} sm={4}>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  sx={{ justifyContent: 'flex-end' }}>
                  <Grid item>
                    <SocialMediaLink
                      platform="github"
                      username="karimfaisall"
                    />
                  </Grid>
                  <Grid item>
                    <SocialMediaLink
                      platform="linkedin"
                      username="karimfaisal-dev"
                    />
                  </Grid>
                  <Grid item>
                    <SocialMediaLink
                      platform="instagram"
                      username="karimfaisall"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Stack spacing={2} justifyContent="center" alignItems="center">
              <Typography variant="subtitle2" color="primary">
                © Made with 💝 by Faisal Karim
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default FooterBlock
