// material-ui

// ==============================|| LOGO SVG ||============================== //

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LogoMain = ({ reverse, ...others }: { reverse?: boolean }) => {
  return (
    <>
      <svg
        width="50"
        height="40"
        viewBox="0 0 98 84"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M95.9488 41.8186L95.9503 41.8211C95.984 41.8783 96 41.9399 96 42C96 42.0601 95.984 42.1217 95.9503 42.1789L95.9488 42.1814L72.6781 81.7827C72.6779 81.7831 72.6777 81.7834 72.6775 81.7838C72.6422 81.8431 72.5886 81.8973 72.518 81.9372C72.4467 81.9774 72.3627 82.0001 72.2749 82H72.2733H25.7267C25.5436 82 25.396 81.9063 25.3216 81.7822C25.3212 81.7816 25.3209 81.781 25.3205 81.7805L2.05123 42.1814L2.04974 42.1789C2.01602 42.1217 2 42.0601 2 42C2 41.9399 2.01602 41.8783 2.04974 41.8211L2.05123 41.8186L25.3219 2.21725C25.3221 2.21681 25.3224 2.21637 25.3227 2.21593C25.3579 2.15678 25.4115 2.10261 25.482 2.06284C25.5533 2.02262 25.6373 1.99993 25.7251 2H25.7267L72.2733 2L72.2749 2C72.3627 1.99993 72.4467 2.02262 72.518 2.06284C72.5885 2.10261 72.6421 2.15678 72.6773 2.21594C72.6776 2.21638 72.6779 2.21681 72.6781 2.21725L95.9488 41.8186ZM70.8573 81.207H72.0019L72.5817 80.2202L94.4439 43.0132L95.0392 42.0001L94.444 40.987L72.5867 3.77997L72.0069 2.79301H70.8622H27.1476H26.0032L25.4233 3.77963L3.55618 40.9866L2.96068 41.9999L3.55609 43.0132L25.4183 80.2202L25.9981 81.207H27.1427H70.8573Z"
          fill="#F6ECA9"
          stroke="#F6ECA9"
          stroke-width="4"
        />
        <path
          d="M40.6055 23.3438V66H34.9512V23.3438H40.6055ZM58.4766 42.5332V47.1621H39.375V42.5332H58.4766ZM61.377 23.3438V27.9727H39.375V23.3438H61.377Z"
          fill="#F6ECA9"
        />
      </svg>
    </>
  )
}

export default LogoMain
