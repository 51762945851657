import { BrowserRouter as Router } from 'react-router-dom'
import './App.css'
import Routes from './routes'
import ThemeCustomization from './theme/ThemeCustomization'

const App = () => (
  <ThemeCustomization>
    <Router>
      <Routes />
    </Router>
  </ThemeCustomization>
)

export default App
