import { Box, Container, Grid, Typography, useTheme } from '@mui/material'
import { motion } from 'framer-motion'
import { Technology } from '../../types/aboutPage'
import HeaderSection from './header/HeaderSection'
import ProfileSection from './profileImage/ProfileSection'
import { textStyle } from './styles/AboutPageStyles'
import TechnologyDisplay from './technology/TechnologyDisplay'
import { Helmet } from 'react-helmet'

interface TechnologyListProps {
  technologies: Technology[]
}

const AboutPage: React.FC<TechnologyListProps> = ({ technologies }) => {
  const theme = useTheme()
  const halfIndex = Math.ceil(technologies.length / 2)
  const firstHalfTechnologies = technologies.slice(0, halfIndex)
  const secondHalfTechnologies = technologies.slice(halfIndex)
  const technologiesString = technologies.join(', ')

  return (
    <Container
      sx={{
        minHeight: '75vh',
        display: 'flex',
        alignItems: 'flex-start'
      }}>
      <Helmet>
        <title>About Me - Faisal Karim</title>
        <meta
          name="description"
          content={`Learn more about Faisal Karim, a passionate web developer skilled in ${technologiesString}. Discover his journey from startup environments to multinational corporations.`}
        />
      </Helmet>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
        sx={{
          pt: { md: 9, xs: 8 },
          pb: { md: 0, xs: 5 },
          ml: { md: 15, xs: 3 }
        }}>
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            sx={{
              [theme.breakpoints.down('md')]: { pr: 0, textAlign: 'left' }
            }}>
            <Grid item xs={12}>
              <motion.div
                initial={{ opacity: 0, translateY: 550 }}
                animate={{ opacity: 1, translateY: 0 }}
                transition={{
                  type: 'spring',
                  stiffness: 150,
                  damping: 30,
                  delay: 0.3
                }}>
                <HeaderSection
                  theme={theme}
                  number="01. "
                  title="About Me"
                  dividerWidth={{ xs: '110px', sm: '300px', md: '200px' }}
                />
              </motion.div>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              lg={7}
              sx={{
                mr: { md: 6, xs: 3 }
              }}>
              <motion.div
                initial={{ opacity: 0, translateY: 550 }}
                animate={{ opacity: 1, translateY: 0 }}
                transition={{
                  type: 'spring',
                  stiffness: 150,
                  damping: 30,
                  delay: 0.2
                }}>
                <Typography sx={textStyle(theme, 'body1')}>
                  Hello! I'm Faisal, passionate about creating distinctive web
                  experiences. My web development adventure started in 2015 with
                  custom WordPress sites, a venture that honed my PHP and
                  JavaScript skills through themes and plugins.
                </Typography>
                <Typography sx={textStyle(theme, 'body1')}>
                  Today, my experience spans from{' '}
                  <Box
                    component="span"
                    sx={{ color: theme.palette.primary.main }}>
                    <span>
                      a dynamic startup to a multinational corporation, and now
                      at Digital Crafts,{' '}
                    </span>
                  </Box>
                  My main focus these days is building user-friendly digital
                  solutions at{' '}
                  <Box
                    component="span"
                    sx={{ color: theme.palette.primary.main }}>
                    <span> Bosch </span>
                  </Box>
                  for various users.
                </Typography>

                <Typography sx={textStyle(theme, 'body1')}>
                  Here's a glimpse of some technologies I've been actively using
                </Typography>
                <TechnologyDisplay
                  firstHalfTechnologies={firstHalfTechnologies}
                  secondHalfTechnologies={secondHalfTechnologies}
                />
              </motion.div>
            </Grid>
            <ProfileSection />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default AboutPage
