import { Box, Divider, Typography } from '@mui/material'
import { theme } from '../../../theme'
import { HeaderSectionProps } from '../../../types/aboutPage'
import { textStyle } from '../styles/AboutPageStyles'

const HeaderSection: React.FC<HeaderSectionProps> = ({
  number,
  title,
  dividerWidth = { xs: '170px', sm: '350px', md: '250px' }
}) => (
  <Box sx={{ display: 'flex', alignItems: 'center', mb: '29px' }}>
    <Typography sx={textStyle(theme, 'header')}>
      <span>{number}</span>
      <Box component="span" sx={{ color: theme.palette.text.primary }}>
        {title}
      </Box>
    </Typography>
    <Divider
      orientation="horizontal"
      flexItem
      sx={{
        width: dividerWidth,
        mt: '16px',
        ml: '12px',
        bgcolor: theme.palette.text.secondary,
        height: '1px'
      }}
    />
  </Box>
)

export default HeaderSection
