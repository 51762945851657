import * as React from 'react'
import { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

// material-ui
import {
  Box,
  Button,
  Drawer,
  Link,
  List,
  ListItemButton,
  Stack,
  Toolbar,
  Typography,
  useScrollTrigger
} from '@mui/material'
import AppBar from '@mui/material/AppBar'
import { useTheme } from '@mui/material/styles'

// project import
import AnimateButton from '../../../common/extendedbuttons/AnimateButton'
import IconButton from '../../../common/extendedbuttons/IconButton'

// assets
import { MenuOutlined } from '@ant-design/icons'

// types
import { default as Logo, default as LogoSection } from '../../../assets/logo'
import useHideOnScroll from '../../../hooks/ScrollAppBar/useHideOnScroll'
import { palette } from '../../../theme/palette'
import { ThemeMode } from '../../../types/config'

// ==============================|| COMPONENTS - APP BAR ||============================== //

// elevation scroll
function ElevationScroll({ layout, children, window }: any) {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 500,
    target: window ? window() : undefined
  })

  const backColorScroll = `#0B1930`
  const backColor = layout !== 'landing' ? backColorScroll : 'transparent'

  return React.cloneElement(children, {
    style: {
      backgroundColor: trigger ? backColorScroll : backColor
    }
  })
}

const scrollToSection = (sectionId: string) => {
  const section = document.getElementById(sectionId)
  if (section) {
    section.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }
}

interface HeaderProps {
  handleDrawerOpen?: () => void
  layout?: string
  authHeader?: boolean
}

const Header = ({
  handleDrawerOpen,
  layout = 'landing',
  authHeader = false,
  ...others
}: HeaderProps) => {
  const theme = useTheme()
  const [drawerToggle, setDrawerToggle] = useState<boolean>(false)
  const logoColor = authHeader ? '#176B87' : 'inherit'
  const drawerbgColor = '#112240'
  const isVisible = useHideOnScroll()

  const drawerToggler = (open: boolean) => (event: any) => {
    if (
      event.type! === 'keydown' &&
      (event.key! === 'Tab' || event.key! === 'Shift')
    ) {
      return
    }
    setDrawerToggle(open)
  }

  return (
    <ElevationScroll layout={layout} {...others}>
      <AppBar
        sx={{
          bgcolor: 'transparent',
          color: theme.palette.text.primary,
          boxShadow: 'none',
          transform: isVisible ? 'translateY(0)' : 'translateY(-110%)',
          transition: 'transform 0.7s',
          fontFamily: 'Fira Code, monospace'
        }}>
        <Box sx={{ px: { xs: 1.5, md: 0, lg: 2 }, py: 1 }}>
          <Toolbar>
            <Stack
              direction="row"
              sx={{
                flexGrow: 1,
                color: '#fff',
                display: { xs: 'none', md: 'block' }
              }}
              alignItems="center"
              onClick={() => scrollToSection('heroPage')}>
              <LogoSection reverse={true} isIcon={false} color={logoColor} />
            </Stack>
            <Stack
              direction="row"
              sx={{
                '& .header-link': {
                  px: 1,
                  color: authHeader ? '#176B87' : 'white',
                  '&:hover': { color: theme.palette.primary.main }
                },
                display: { xs: 'none', md: 'block' }
              }}
              spacing={2}>
              <Link
                className="header-link"
                color="#EDEDED"
                onClick={() => scrollToSection('about')}
                underline="none">
                <Typography
                  component="span"
                  sx={{
                    color: 'primary.main',
                    fontFamily: 'Fira Code, monospace'
                  }}>
                  01.
                </Typography>{' '}
                About
              </Link>
              <Link
                className="header-link"
                color="#EDEDED"
                onClick={() => scrollToSection('experience')}
                underline="none">
                <Typography
                  component="span"
                  sx={{
                    color: 'primary.main',
                    fontFamily: 'Fira Code, monospace'
                  }}>
                  02.
                </Typography>{' '}
                Experience
              </Link>
              {/* <Link
                className="header-link"
                color="#EDEDED"
                onClick={() => scrollToSection('work')}
                underline="none">
                <Typography
                  component="span"
                  sx={{
                    color: 'primary.main',
                    fontFamily: 'Fira Code, monospace'
                  }}>
                  03.
                </Typography>{' '}
                Work
              </Link> */}
              <Link
                className="header-link"
                color="#EDEDED"
                onClick={() => scrollToSection('contact')}
                underline="none">
                <Typography
                  component="span"
                  sx={{
                    color: 'primary.main',
                    fontFamily: 'Fira Code, monospace'
                  }}>
                  04.
                </Typography>{' '}
                Contact
              </Link>
              <Box sx={{ display: 'inline-block' }}>
                <AnimateButton>
                  <Button
                    component={RouterLink}
                    to="https://drive.google.com/file/d/1vYa6YZw3r5frOGl-dGNGGF6oKJsm8wFz/view?usp=drive_link"
                    target="_blank"
                    disableElevation
                    variant="outlined"
                    sx={{
                      color: theme.palette.text.primary,
                      borderColor: 'palette.primary.main',
                      borderRadius: '5px',
                      textTransform: 'none',
                      px: '30px',
                      ml: '18px',
                      fontFamily: 'Roboto, sans-serif'
                    }}>
                    Resume
                  </Button>
                </AnimateButton>
              </Box>
            </Stack>
            <Box
              sx={{
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
                display: { xs: 'flex', md: 'none' }
              }}>
              <Typography
                component="div"
                sx={{ textAlign: 'left', display: 'inline-block' }}>
                <Logo reverse to="/" />
              </Typography>
              <Stack direction="row" spacing={2}>
                <IconButton
                  color="secondary"
                  {...(layout === 'component'
                    ? { onClick: handleDrawerOpen }
                    : { onClick: drawerToggler(true) })}
                  sx={{
                    '&:hover': {
                      bgcolor: drawerbgColor
                    }
                  }}>
                  <MenuOutlined
                    style={{
                      color:
                        theme.palette.mode === ThemeMode.DARK
                          ? 'inherit'
                          : theme.palette.grey[100]
                    }}
                  />
                </IconButton>
              </Stack>
              <Drawer
                anchor="right"
                open={drawerToggle}
                onClose={drawerToggler(false)}
                sx={{
                  '& .MuiDrawer-paper': { backgroundColor: drawerbgColor },
                  '& .MuiBackdrop-root': {
                    backgroundColor: 'rgba(17, 34, 64, 0.22)',
                    backdropFilter: 'blur(3.5px)'
                  },
                  width: '650px',
                  fontFamily: 'Fira Code, monospace'
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: { xs: '280px', md: '380px' },
                    height: '100vh',
                    top: '0px',
                    bottom: '0px',
                    right: '0px',
                    '& .MuiListItemIcon-root': {
                      minWidth: 28
                    }
                  }}
                  role="presentation"
                  onClick={drawerToggler(false)}
                  onKeyDown={drawerToggler(false)}>
                  <List>
                    <Link
                      className="header-link"
                      color="#EDEDED"
                      onClick={() => scrollToSection('about')}
                      underline="none"
                      fontSize={20}>
                      <ListItemButton
                        component="span"
                        sx={{
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          my: '30px'
                        }}>
                        <Stack direction="column" alignItems="center">
                          <Typography
                            component="span"
                            sx={{
                              color: 'primary.main',
                              fontSize: '17px',
                              fontFamily: 'Fira Code, monospace'
                            }}>
                            {' '}
                            01.
                          </Typography>
                          About
                        </Stack>
                      </ListItemButton>
                    </Link>

                    <Link
                      className="header-link"
                      color="#EDEDED"
                      onClick={() => scrollToSection('experience')}
                      underline="none"
                      fontSize={20}
                      marginBottom={4}>
                      <ListItemButton
                        component="span"
                        sx={{
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          my: '30px'
                        }}>
                        <Stack direction="column" alignItems="center">
                          <Typography
                            component="span"
                            sx={{
                              color: 'primary.main',
                              fontSize: '17px',
                              fontFamily: 'Fira Code, monospace'
                            }}>
                            {' '}
                            02.
                          </Typography>
                          Experience
                        </Stack>
                      </ListItemButton>
                    </Link>

                    {/* <Link
                      className="header-link"
                      color="#EDEDED"
                      onClick={() => scrollToSection('work')}
                      underline="none"
                      fontSize={20}
                      marginBottom={4}>
                      <ListItemButton
                        component="span"
                        sx={{
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          my: '30px'
                        }}>
                        <Stack direction="column" alignItems="center">
                          <Typography
                            component="span"
                            sx={{
                              color: 'primary.main',
                              fontSize: '17px',
                              fontFamily: 'Fira Code, monospace'
                            }}>
                            {' '}
                            03.
                          </Typography>
                          Work
                        </Stack>
                      </ListItemButton>
                    </Link> */}

                    <Link
                      className="header-link"
                      color="#EDEDED"
                      onClick={() => scrollToSection('contact')}
                      underline="none"
                      fontSize={20}
                      marginBottom={4}>
                      <ListItemButton
                        component="span"
                        sx={{
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}>
                        <Stack direction="column" alignItems="center">
                          <Typography
                            component="span"
                            sx={{
                              color: 'primary.main',
                              fontSize: '17px',
                              fontFamily: 'Fira Code, monospace'
                            }}>
                            {' '}
                            04.
                          </Typography>
                          Contact
                        </Stack>
                      </ListItemButton>
                    </Link>
                    <Box sx={{ display: 'inline-block' }}>
                      <AnimateButton>
                        <Button
                          component={RouterLink}
                          to="https://drive.google.com/file/d/1vYa6YZw3r5frOGl-dGNGGF6oKJsm8wFz/view?usp=drive_link"
                          target="_blank"
                          disableElevation
                          variant="outlined"
                          sx={{
                            mr: '4px',
                            color: 'palette.primary.main',
                            border: `1px solid ${palette.primary.main}`,
                            borderRadius: '5px',
                            textTransform: 'none',
                            px: '30px',
                            my: '90px',
                            fontSize: { xs: '1.0rem', md: '1.2rem' },
                            fontFamily: 'Roboto, sans-serif'
                          }}>
                          Resume
                        </Button>
                      </AnimateButton>
                    </Box>
                  </List>
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Box>
      </AppBar>
    </ElevationScroll>
  )
}

export default Header
