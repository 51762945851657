import React, { ReactNode } from 'react'
import { ThemeProvider, CssBaseline } from '@mui/material'
import { theme } from './'

type ThemeCustomizationProps = {
  children: ReactNode
}

const ThemeCustomization = ({ children }: ThemeCustomizationProps) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </ThemeProvider>
)

export default ThemeCustomization
