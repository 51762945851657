import { Box } from '@mui/material'
import React from 'react'
import SocialMediaIcons from '../../common/socialMediaIcons/SocialMediaIcons'
import Header from './Header'

interface WebsiteLayoutProps {
  children: React.ReactNode
}

const MainLayout: React.FC<WebsiteLayoutProps> = ({ children }) => {
  return (
    <Box>
      <Header />
      <main>{children}</main>
      <SocialMediaIcons flexDirection="column" includeEmailLink={true} />
    </Box>
  )
}

export default MainLayout
