import { Link, Typography, Divider, Box } from '@mui/material'
import { theme } from '../../theme'

interface EmailLinkProps {
  email: string
  orientation: 'vertical' | 'horizontal'
}

const EmailLink: React.FC<EmailLinkProps> = ({ email, orientation }) => {
  const mailtoLink = `mailto:${email}`
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: orientation === 'vertical' ? 'column' : 'row'
      }}>
      {email.split('').map((char, index) => (
        <Link
          href={mailtoLink}
          target="_blank"
          underline="none"
          key={index}
          sx={{ color: theme.palette.text.primary }}>
          <Typography
            sx={{
              writingMode:
                orientation === 'vertical' ? 'vertical-rl' : 'horizontal-tb'
            }}>
            {char}
          </Typography>
        </Link>
      ))}
      <Divider
        orientation="vertical"
        flexItem
        sx={{
          height: '90px',
          mr: 1.4,
          mt: 2,
          borderColor: theme.palette.text.secondary
        }}
      />
    </Box>
  )
}

export default EmailLink
