import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined'
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { theme } from '../../../theme'
import { Technology } from '../../../types/aboutPage'

interface TechnologyListProps {
  technologies: Technology[]
}

const TechnologyList: React.FC<TechnologyListProps> = ({ technologies }) => (
  <List>
    {technologies.map((technology, index) => (
      <ListItem key={index}>
        <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}>
          <PlayArrowOutlinedIcon color="primary" fontSize="small" />
        </ListItemIcon>
        <ListItemText
          primary={technology}
          primaryTypographyProps={{ style: { fontSize: '0.7rem' } }}
          sx={{
            color: theme.palette.text.secondary,
            lineHeight: { xs: 1.0, sm: 1.2, md: 9.5 }
          }}
        />
      </ListItem>
    ))}
  </List>
)

export default TechnologyList
