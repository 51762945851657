import { GitHub, Instagram, LinkedIn } from '@mui/icons-material'
import { Link } from '@mui/material'
import React from 'react'
import { theme } from '../../theme'
import AnimateButton from '../extendedbuttons/AnimateButton'

interface SocialMediaLinkProps {
  platform: 'github' | 'linkedin' | 'instagram'
  username: string
}

const SocialMediaLink: React.FC<SocialMediaLinkProps> = ({
  platform,
  username
}) => {
  let href = ''
  let IconComponent = GitHub

  switch (platform) {
    case 'github':
      href = `https://github.com/${username}`
      IconComponent = GitHub
      break
    case 'linkedin':
      href = `https://linkedin.com/in/${username}`
      IconComponent = LinkedIn
      break
    case 'instagram':
      href = `https://instagram.com/${username}`
      IconComponent = Instagram
      break
    default:
      throw new Error('Unsupported platform')
  }

  return (
    <AnimateButton>
      <Link href={href} target="_blank">
        <IconComponent
          sx={{
            color: theme.palette.text.primary,
            mb: 2,
            '&:hover': {
              color: theme.palette.primary.main,
              transition: 'background-color 0.6s'
            }
          }}
        />
      </Link>
    </AnimateButton>
  )
}

export default SocialMediaLink
